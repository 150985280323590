import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { StaticQuery, graphql } from 'gatsby';

import { Img } from './Images';
import { remCalc, position, mq } from '../../helpers/stylehelpers';

import prizeCircle from '../../images/homestage/prize-circle.svg';

const propTypes = {
    data: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

const Wrapper = styled.div`
    display: none;
    ${mq.large`display: block;`};
`;

const CircleImageWrapper = styled.div`
    display: block;
    position: relative;
    margin: 2.5em 2em 3.5em 1em;
    width: ${remCalc(315)};
    height: ${remCalc(317)};

    ${mq.xlarge`
        height: ${remCalc(360)};
        width: ${remCalc(375)};
    `};

    ${mq.xxlarge`
        height: ${remCalc(437)};
        width: ${remCalc(435)};
    `};

    > div {
        display: block;
    }

    &:after {
        content: '';
        background-image: url(${prizeCircle});
        background-repeat: no-repeat;
        background-size: 98%;
        display: inline-block;
        height: ${remCalc(147)};
        width: ${remCalc(150)};

        ${position({ bottom: '0', right: '0' })};
        transform: translate(30%, 40%);
        height: ${remCalc(165)};
        width: ${remCalc(170)};

        ${mq.xlarge`
            height: ${remCalc(200)};
            width: ${remCalc(205)};
        `};

        ${mq.xxlarge`
            height: ${remCalc(275)};
            width: ${remCalc(280)};
        `};
    }
`;

const HomestageCircles = ({ data }) => (
    <Wrapper>
        <CircleImageWrapper>
            <Img src={data.imageCircle.childImageSharp.fluid} />
        </CircleImageWrapper>
    </Wrapper>
);

HomestageCircles.propTypes = propTypes;

export default props => (
    <StaticQuery
        query={graphql`
            query HomestageCirclesQuery {
                imageCircle: file(relativePath: { eq: "homestage/image-circle.png" }) {
                    childImageSharp {
                        fluid(maxWidth: 470, quality: 80) {
                            ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        }
                    }
                }
            }
        `}
        render={data => <HomestageCircles data={data} {...props} />}
    />
);
