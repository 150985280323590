import React from 'react';

import { phased } from '../helpers/phases';
import BaseLayout from '../components/layouts/BaseLayout';
import Stage from '../components/layouts/Stage';
import StageIntro from '../components/elements/StageIntro';

import { Section, FlexContainer } from '../components/layouts/Containers';
import { Headline, P } from '../components/elements/Typography';
import { Link, ButtonLink } from '../components/elements/ButtonsAndLinks';
import { Img } from '../components/elements/Images';
import HomestageCircles from '../components/elements/HomestageCircles';

import logoLSVbwsj from '../images/logos/lsv-bwsj.png';
import logoKMBW from '../images/logos/km-bw.png';
import logoEuropapark from '../images/logos/europapark.png';

import { downloadSjfp2020Preistraeger } from '../helpers/downloads';

const introContentPhase1And2 = (
    <>
        <P>
            Teamgeist, Solidarität und Fairplay – das leben die 11.300 Sportvereine in
            Baden-Württemberg. Sie übernehmen damit eine wichtige gesellschaftspolitische Aufgabe,
            gerade auch durch ihre Jugendarbeit. Kinder und Jugendliche erlernen im Verein neben der
            Freude an der Bewegung, am sportlichen Kräftemessen auch den rücksichtsvollen Umgang mit
            Gleichaltrigen. Das Selbstbewusstsein wird ebenso trainiert, wie die sportliche
            Leistungsfähigkeit. Durch die Vermittlung von Werten erhalten Kinder und Jugendliche
            einen verlässlichen Kompass, der sie ein Leben lang begleitet. Davon profitiert unsere
            Gesellschaft als Ganzes.
        </P>
        <P gap="l">
            Der LOTTO Sportjugend-Förderpreis würdigt diese vorbildliche Jugendarbeit. Bereits zum
            zwölften Mal schreibt Lotto Baden-Württemberg den Wettbewerb gemeinsam mit der
            Baden-Württembergischen Sportjugend und dem Kultusministerium aus.<br />
            <strong>Er ist mit insgesamt 100.000 Euro dotiert.</strong>
        </P>
        <div>
            <iframe
                width="560"
                height="315"
                src="https://www.youtube-nocookie.com/embed/TJRVekLENAI"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Video"
            />
        </div>
        <P gap="l">
            <br /> Selten war gesellschaftlicher Zusammenhalt stärker gefragt als in der
            Corona-Krise. Auch hier war auf die Sportvereine Verlass. Mit großer Flexibilität und
            Kreativität haben sie im Trainingsalltag improvisiert oder durch Nachbarschaftshilfe
            unterstützt. Für dieses beispielgebende Engagement während der Corona-Pandemie schreiben
            wir dieses Mal Sonderpreise aus.
        </P>
    </>
);

const IndexPage = () => (
    <BaseLayout title="" description="LOTTO AWARD - Sportjugend-Förderpreis 2018 für Sportvereine">
        <Stage columnOnMobile>
            <StageIntro
                headline={{ text: 'Der Wettbewerb' }}
                subline="für baden-württembergische Sportvereine"
                copy={phased([
                    'Reichen Sie Ihre Bewerbung bis zum 11.01.2021 ein und sichern Sie sich die Chance auf bis zu 7.500€ für Ihren Verein',
                    'Die Bewerbungsfrist ist abgelaufen. Herzlichen Dank an alle Teilnehmer! Wir geben die Preisträger bald auf dieser Seite bekannt.',
                    'Der Marine Verein Wangen ist Landessieger beim Lotto Sportjugend-Förderpreis 2020. Der Segelsportverein aus dem Allgäu sicherte sich den Sieg mit seiner Bewerbung zur selbstorganisierten demokratischen Jugendarbeit. Insgesamt 83 Sportvereine aus ganz Baden-Württemberg wurden bei der virtuellen Preisverleihung am 24. Juli im Europa-Park für ihre vorbildliche Jugendarbeit gewürdigt. Die Verleihung gibt es hier zum Nachschauen.',
                ])}
                links={[
                    phased([
                        { text: 'Jetzt bewerben!', to: '/online-bewerbung' },
                        { text: 'Preise ansehen', to: '/preise' },
                        {
                            text: 'Gewinner ansehen',
                            to: downloadSjfp2020Preistraeger,
                            target: '_blank',
                        },
                    ]),
                    {
                        text: 'Zum YouTube-Kanal',
                        to: 'https://www.youtube.com/watch?v=umrsOPMc4dQ',
                        target: '_blank',
                    },
                ]}
            />
            <HomestageCircles />
        </Stage>
        <Section container="s" textAlign="center">
            <Headline level="h2">
                Lotto Sportjugend-Förderpreis:<br />
                Landessieg geht an den Marine Verein Wangen / 100.000 Euro für vorbildliche Vereine
                im Land
            </Headline>
            {phased([
                introContentPhase1And2,
                introContentPhase1And2,
                <>
                    <P>
                        Eine Jury unter dem Vorsitz von Professor Dr. Klaus Bös ermittelte die
                        Preisträger. Prämiert wurden Aktionen der Vereinsjugendarbeit aus den Jahren
                        2019 und 2020. Sie reichten vom Engagement für die Gesellschaft und das
                        Gemeinwohl, Partizipation von Kindern und Jugendlichen, Veranstaltungen bis
                        hin zu Freizeitaktivitäten. Auch Angebote der digitalen Jugendarbeit,
                        Projekte zu Inklusion, Integration und Nachhaltigkeit waren dabei.
                    </P>
                    <P>
                        Den zweiten, mit 5.000 Euro prämierten Platz belegte die KraftWerkstatt
                        Lörrach mit der Aktion „gemeinsam gegen Plastik“. Platz 3 und 2.500 Euro
                        Preisgeld gingen an den TSV Berkheim aus Esslingen am Neckar für „Korni –
                        Unser Vereinsmaskottchen“. Für beispielgebende Aktionen in der Corona-Zeit
                        vergab die Jury zehn mit jeweils 1.000 Euro dotierte Sonderpreise. Daneben
                        gab es Geldpreise für weitere 70 Vereine. Diese liegen zwischen 500 Euro und
                        2.000 Euro. Lotto-Geschäftsführer Georg Wacker lobte die Leistung der
                        Gewinner: „Die Preisträger beweisen wirklich eindrucksvoll, wie sehr der
                        Sport die Menschen bewegt und welch tolle Kreativität er immer wieder
                        entfaltet. Unser Förderpreis spiegelt seit vielen Jahren die enge
                        Partnerschaft zwischen Lotto und dem baden-württembergischen Sport. Darauf
                        sind wir sehr stolz.“
                    </P>
                    <P>
                        Den zweiten, mit 5.000 Euro prämierten Platz belegte die KraftWerkstatt
                        Lörrach mit der Aktion „gemeinsam gegen Plastik“. Platz 3 und 2.500 Euro
                        Preisgeld gingen an den TSV Berkheim aus Esslingen am Neckar für „Korni –
                        Unser Vereinsmaskottchen“. Für beispielgebende Aktionen in der Corona-Zeit
                        vergab die Jury zehn mit jeweils 1.000 Euro dotierte Sonderpreise. Daneben
                        gab es Geldpreise für weitere 70 Vereine. Diese liegen zwischen 500 Euro und
                        2.000 Euro. Lotto-Geschäftsführer Georg Wacker lobte die Leistung der
                        Gewinner: „Die Preisträger beweisen wirklich eindrucksvoll, wie sehr der
                        Sport die Menschen bewegt und welch tolle Kreativität er immer wieder
                        entfaltet. Unser Förderpreis spiegelt seit vielen Jahren die enge
                        Partnerschaft zwischen Lotto und dem baden-württembergischen Sport. Darauf
                        sind wir sehr stolz.“
                    </P>
                    <P>
                        Die Präsidentin des Landessportverbandes Baden-Württemberg (LSVBW) Elvira
                        Menzer-Haasis betonte: „Ich freue mich sehr über die vielen eingereichten
                        Projekte. Insbesondere die Vielfältigkeit der verschiedenen Projekte
                        untermauert den Ideenreichtum unserer Sportvereine im Land. Über die letzten
                        zwei Jahre hinweg haben sie gezeigt, dass Verlass auf sie ist. Mit welchem
                        Selbstverständnis sie für die Gemeinschaft einstehen ist unübertrefflich.“
                    </P>
                    <P>
                        Baden-Württembergs Kultusministerin Theresa Schopper, zur virtuellen
                        Verleihung zugeschaltet, sagte: „Es ist beeindruckend, mit welchem
                        Engagement und welcher Kreativität sich Sportlerinnen und Sportler für
                        verschiedenste Belange einsetzen. Ich gratuliere allen von Herzen zu Ihren
                        tollen Leistungen. Bleiben Sie dran, das Land braucht Ihren tollen Einsatz.
                        Zumal wir gerade in den vergangenen Monaten gemerkt haben, wie schmerzlich
                        es ist, wenn Sport und seine positiven Auswirkungen auf unser Gemeinwohl
                        eingeschränkt werden.“<br />
                        Wegen der Corona-Pandemie konnten die Vereine ihre Urkunden nicht persönlich
                        entgegennehmen. Stattdessen wurde die Verleihung als Livestream, moderiert
                        von Michael Antwerpes, aus dem Digital-Studio des Europa-Park übertragen.
                        Für eine sportliche Note bei der Preisverleihung sorgten
                        Speerwurf-Weltmeisterin Christina Obergföll und Paralympics-Sieger Niko
                        Kappel. Das Rahmenprogramm steuerten Slackliner Benni Schmid, Beatboxer
                        ROBEAT sowie die internationalen Artisten des Europa-Park bei. Der Ausflug
                        der Vereins-Delegationen in Deutschlands größten Freizeitpark können sie auf
                        Einladung der Inhaberfamilie Mack zu einem späteren Zeitpunkt nachholen.
                        Europa-Park Inhaber Roland Mack gratulierte: „Soziales Engagement und
                        ehrenamtlicher Einsatz sind von zentraler Bedeutung für unsere Gesellschaft
                        und daher sind wir überwältigt von den mehr als 550 Einreichungen der
                        Vereine für den diesjährigen Lotto Sportjugend-Förderpreis.“<br />
                        Der Lotto Sportjugend-Förderpreis wird seit 1998 im zweijährigen Turnus und
                        in Zusammenarbeit mit dem Kultusministerium sowie der
                        Baden-Württembergischen Sportjugend im LSVBW ausgeschrieben. Über 2.500
                        Vereine nahmen bisher teil – die Preisgelder lagen in Summe bei über 1,1
                        Millionen Euro.
                    </P>
                </>,
            ])}

            {(({ to, text, target }) => (
                <ButtonLink to={to} target={target}>
                    {text}
                </ButtonLink>
            ))(
                phased([
                    { text: 'Jetzt bewerben!', to: '/online-bewerbung' },
                    { text: 'Preise ansehen', to: '/preise' },
                    {
                        text: 'Gewinner ansehen',
                        to: downloadSjfp2020Preistraeger,
                        target: '_blank',
                    },
                ])
            )}
        </Section>
        <Section>
            <Headline level="h2" gridColStart="1" gap="m">
                Unterstützt durch
            </Headline>
            <FlexContainer grid size="m" textAlign="center" justifyContent="space-around">
                <Link
                    to="https://www.lsvbw.de/sportwelten/sportjugend/"
                    target="_blank"
                    rel="noopener noreferrer"
                    display="block"
                    layer={1}
                    gap="m"
                    bgColor="white"
                >
                    <Img
                        title="Baden-Württembergische Sportjugend (BWSJ) im Landessportverband Baden-Württemberg"
                        alt="Logo des LSV/bwsj"
                        src={logoLSVbwsj}
                    />
                </Link>
                <Link
                    to="https://www.km-bw.de/,Lde/Startseite"
                    target="_blank"
                    rel="noopener noreferrer"
                    display="block"
                    layer={1}
                    bgColor="white"
                    gap="m"
                >
                    <Img
                        title="Baden-Württembergische Sportjugend (BWSJ) im Landessportverband Baden-Württemberg"
                        alt="Logo des LSV/bwsj"
                        src={logoKMBW}
                    />
                </Link>
                <Link
                    to="https://www.europapark.de/de"
                    target="_blank"
                    rel="noopener noreferrer"
                    display="block"
                    layer={1}
                    gap="m"
                    bgColor="white"
                >
                    <Img title="Europapark" alt="Logo des Europaparks" src={logoEuropapark} />
                </Link>
            </FlexContainer>
        </Section>
    </BaseLayout>
);

export default IndexPage;
